<template>
  <form class="form-page-form__block">
    <inputText
      label="Email"
      placeholder="example@yandex.ru"
      type="email"
      :field="email"
      @input="email = $event"
      autocomplete="username"
      :class="{
        error: validationStatus($v.email) || errorMessage.email,
      }"
      :error="
        (!$v.email.required && $v.email.$error
          ? 'Обязательное поле'
          : !$v.email.email && $v.email.$error
          ? 'Некорректный e-mail'
          : '') || errorMessage.email
      "
    />
    <inputText
      label="Введите пароль" 
      type="password"
      :field="password"
      autocomplete="current-password"
      :class="{
        error: validationStatus($v.password) || this.errorMessage.password,
      }"
      :error="
        (!$v.password.required && $v.password.$error
          ? 'Обязательное поле'
          : !$v.password.minLength && $v.password.$error
          ? 'Минимум ' + $v.password.$params.minLength.min + ' символов'
          : '') || this.errorMessage.password
      "
      @input="password = $event"
    />
    <div class="form-page-form__row">
      <router-link
        :to="{ name: 'Recover' }"
        class="form-page-form__forget-link _undeline-link"
        @click.prevent="switchToRecover()"
      >
        <span @click="gtmEvent('', 'password_recovery', 'password_recovery_start');">Забыли пароль?</span>
      </router-link>
    </div>

    <div class="form-page-form__footer">
      <button
        type="button"
        class="btn btn--primary btn--icon"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Войти
        <img src="../../assets/img/spinner.svg" class="loader" alt="" />
      </button>
      <div v-if="!(new Date() >= new Date('2024-06-01T00:00:00'))" class="form-page__another-option">
        <p>
          Нет профиля?
          <router-link :to="{ name: 'Signup' }" class="_undeline-link"
            >Зарегистрируйтесь</router-link
          >
        </p>
      </div>
    </div>
  </form>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "LoginForm",
  props: ["userPhone"],
  data() {
    return {
      ready: false,
      email: null,
      password: null,
      checkbox: true,
      errorMessage: {
        email: null,
        password: null,
        checkbox: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    email: { required, email },
    password: { required, minLength: minLength(4) },
  },

  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        password: null,
      };
    },

    close() {
      this.$store.commit("SHOW_POPUP_CONTENT", false);
    },
    switchToRecover() {
      this.$modal.hide("authorization");
      this.$modal.show("recover");
      this.gtmEvent("", "password_recovery", "password_recovery_start");
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    pushTo(link) {
      window.open(link, "_blank").focus();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        const errors = Object.keys(this.errorMessage);
        for (let index = 0; index < errors.length; index++) {
          const element = errors[index];
          if (this.$v[element].$error) {
            this.gtmEvent(element, 'login', 'login_error', 'login')
          }
        }
      }
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.gtmEvent("", "login", "login_start");

        this.$store
          .dispatch("SignIn", {
            login: this.email,
            password: this.password,
          })
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.email) {
                this.errorMessage.email = r.error.email;
                this.gtmEvent("login", "login", "login_error", "login");
                fieldsError = true;
              } else if (r.error && r.error.login) {
                this.errorMessage.email = r.error.login[0];
                this.gtmEvent(
                  "login",
                  "login",
                  "login_error",
                  "login"
                );
                fieldsError = true;
              }

              if (r.error && r.error.password) {
                [this.errorMessage.password] = r.error.password;
                fieldsError = true;
                this.gtmEvent(
                  'password',
                  "login",
                  "login_error",
                  "login"
                );
              }

              if (r.message && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! " + r.message,
                });
                this.gtmEvent(r.message, "login", "login_error", "login");
              }
            } else {
              this.gtmEvent("", "login", "login_success");

              this.submitStatus = null;

              this.$store.commit("SET_TOKEN", r.data.auth_key);
              // this.gtmEvent("enter", "form", "send");
              // this.gtmEvent("cabinet_success", "do", "internal");
              this.$store.dispatch("GetProfile").then((res) => {
                if (!res.result) {
                  // this.$router.push("/");
                } else {
                  this.$root.$emit("onLogined");
                  this.$router.push("/profile");
                  if (window.promoTools)
                    window.promoTools.checkGrabberWidget.settings = {
                      userUuid: this.$store.getters?.user?.uuid,
                    };
                }
              });
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
    showSignUpModal() {
      this.$modal.hide("authorization");
      if (new Date() >= new Date('2024-06-01T00:00:00')) {
        this.$modal.show("temporarily_unavailable");
      } else {
        this.$modal.show("signup");
      }
      // this.$modal.show("temporarily_unavailable");
    },
  },

  mounted() {
    this.phone = this.userPhone || null;
  },
  components: {
    inputText,
  },
};
</script>

<style lang="scss" scoped>
.authorization {
  h3 {
    text-align: center;
    margin-top: rem(8px);
  }

  &__signin {
    width: 100%;
    margin: 0 auto;
    &-fields {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    &-footer {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
    }
    .forget-link {
      white-space: nowrap;
      font-size: rem(14px);
      color: rgba(32, 0, 130, 1);
      text-align: right;
    }

    .forget-link:hover {
      text-decoration: none;
    }
  }
  &__checkbox {
    white-space: nowrap;
  }
  &__row {
    width: 100%;
    display: flex;
    margin-top: rem(10px);
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(20px);
  }

  @media (min-width: $md) {
    &__checkbox {
    }
    &__signin {
      &-fields {
      }
      .forget-link {
        margin: 0 0 0 auto;
        font-size: rem(18px);
        line-height: rem(29px);
      }
      &-footer {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        .btn {
          min-width: auto;
          margin: 0;
          &:not(:last-of-type) {
            margin-right: rem(24px);
          }
        }
      }
    }
    &__row {
      flex-direction: row;
      align-items: center;
      margin-bottom: rem(15px);
      margin-top: rem(10px);
    }
  }
}
</style>
